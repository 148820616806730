import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ListingsList from "../components/ListingsList"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="" />
    <ListingsList title="Bostäder till salu i Solna" listings={data.hemnetApi.searchListings.listings} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query HemneoStaticIndexQuery {
    hemnetApi {
      searchListings(
        search: {
          locationIds: ["18028"]
          upcoming: EXCLUDE
          deactivatedBeforeOpenHouse: EXCLUDE
        }
        sort: NEWEST
        limit: 100
        offset: 0
      ) {
        total
        limit
        offset
        sort
        listings {
          id
          listingHemnetUrl
          streetAddress
          askingPrice {
            amount
          }
          fee {
            amount
          }
          tenure {
            name
          }
          housingForm {
            name
          }
          ... on Hemnet_ActivePropertyListing {
            description
            thumbnail {
              url
              originalHeight
              originalWidth
            }
          }
          ... on Hemnet_Project {
            description
            thumbnail {
              url
              originalHeight
              originalWidth
            }
          }
          ... on Hemnet_ProjectUnit {
            description
            thumbnail {
              url
              originalHeight
              originalWidth
            }
          }
        }
      }
    }
  }
`
